<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Ticketing from "@/services/Ticketing";
import Swal from "sweetalert2";
import ModalAddSiipSetup from "@/components/modals/ticketing/modalAddSiipSetup";


export default {
    components: { Layout, PageHeader, ModalAddSiipSetup, },
    page: {
        title: "SIIP Setup",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    data() {
        return {
            title: "SIIP Setup",
            items: [
                {
                    text: "Ticketing",
                },
                {
                    text: "SIIP Setup",
                    active: true,
                    href: "/ticketing/siip_setup",
                },
            ],
            isBusy: false,
            error: null,
            showModal: false,
            modalData: {},
            link_id: null,
            tableData: [],
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "pdate_formatted",
            sortDesc: true,
            fields: [
                {
                    key: "stand",
                    label: "Stand",
                    sortable: true,
                },
                {
                    key: "area",
                    label: "Area",
                    sortable: true,
                },
                {
                    key: "row",
                    label: "Row",
                    sortable: true,
                },
                {
                    key: "seat",
                    label: "Seat",
                    sortable: true,
                },
                "action",
            ],
        };
    },
    async created() {
        await this.getSiipSetup()
    },
    methods: {
        async success() {
            this.getSiipSetup();
        },
        refreshTableData() {
            this.getSiipSetup();
        },
        async getSiipSetup() {
            try {
                this.toggleBusy();
                const response = await Ticketing.getSiipSetup()
                this.tableData = response.data.data
                this.totalRows = this.tableData.length
                this.toggleBusy();
            } catch (error) {
                this.toggleBusy();
                this.error = error.response.data.error ? error.response.data.error : "";
                this.tableData = []
                this.totalRows = 0;
            }
        },


        async deleteSiipSetup(item) {
            Swal.fire({
                title: "Remove SIIP Setup?",
                text: "This SIIP Setup will be removed!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Remove!",
            }).then(async (result) => {
                if (result.value) {
                    try {
                        const response = await Ticketing.deleteSiipSetup({
                            stand: item.stand,
                            area: item.area,
                            row: item.row,
                            seat: item.seat
                        });
                        if (response.data) {
                            await this.getSiipSetup();
                            Swal.fire("Remove SIIP Setup", "SIIP Setup is successfully removed!", "success");
                        } else {
                            const error = response.data.error ? response.data.error : 'Failed';
                            Swal.fire("Fail!", error, "warning");
                        }
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                    }
                }
            });
        },
        callModalAddSiipSetup() {
            this.$bvModal.show("add_siip_setup");
        },
        submitForm() {
            this.applyFilters();
        },
        resetFilters() {
            this.filterInput = {
                active: '',
                type: ''
            };
            this.applyFilters();
        },
        toggleBusy() {
            this.isBusy = !this.isBusy;
        },
        toggleBusySetup() {
            this.isBusySetup = !this.isBusySetup;
        },

        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

    },
    middleware: "authentication",
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div>
                    <button type="button" class="btn btn-success mb-3" @click="callModalAddSiipSetup()">
                        <i class="mdi mdi-plus me-1"></i> Add New SIIP Setup
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Total SIIP Setup: {{ totalRows }}</h4>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm"
                                            :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                                            class="form-control form-control-sm ms-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table :busy="isBusy" :items="tableData" :fields="fields" responsive="sm"
                                :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                                show-empty empty-text="No Data Found" @filtered="onFiltered">


                                <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover
                                                title="Remove SIIP Setup" @click="deleteSiipSetup(data.item)">
                                                <i class="uil uil-trash-alt font-size-18"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </template>

                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="totalRows"
                                            :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalAddSiipSetup @onRefresh="refreshTableData"></ModalAddSiipSetup>
    </Layout>
</template>