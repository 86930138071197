<script>
import Ticketing from '@/services/Ticketing';
import Swal from "sweetalert2";
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import validationMessages from '@/components/validations';
import {minLength, numeric, required} from "vuelidate/lib/validators";

export default {
  emits: ["success", "onRefresh"],
  components: { Multiselect,validationMessages },
  data() {
    return {
      csrf_token: localStorage.getItem('csrf_token'),
      stand: [],
      area: [],
      row: [],
      seat: [],
      Data: [],
      standOptions: [],
      areaOptions: [],
      rowOptions: [],
      seatOptions: [],
      submitted: false,
      showModal: false,
      tryingToEdit: false,
      modalKey: 0,
      tryingToSubmit: false,
      showLoader: false,
    };
  },
  validations: {
           stand: {
               required,
           },
          },
  mounted() {
    this.getSiipSetup();
  },
  watch: {
    stand(newVal) {
      this.onStandChange();
    },
    area(newVal) {
      this.onAreaChange();
    },
    row(newVal) {
      this.onRowChange();
    },
  },
  methods: {
    async getSiipSetup() {
      try {
        this.showLoader = true;  
        const response = await Ticketing.getSiipSetupOptions();
        if (response.data && response.data.data && response.data.data.length) {
          this.Data = response.data.data;
          this.populateStandOptions();
        } else {
          console.error("No data received from API");
        }
      } catch (error) {
        console.error("API call failed:", error);
        this.failedmsg(error.response?.data?.error || "Failed to fetch SIIP setup options.");
      }
      finally {
      this.showLoader = false; 
    }
    },
 
populateStandOptions() {
  
  this.standOptions = this.Data
    .filter(stand => stand.text && stand.text.trim() !== "") 
    .map(stand => ({
      value: stand.value,
      text: stand.text,
    }));

},
 
    populateAreaOptions() {
      if (this.stand.length === 1) {
        const selectedStandValue = this.stand[0].value; 
        const selectedStand = this.Data.find(stand => stand.value === selectedStandValue);

        if (selectedStand && selectedStand.areas) {
          this.areaOptions = selectedStand.areas.map(area => ({
            value: area.value,
            text: area.text,
          }));
        } else {
          this.areaOptions = [];
        }
      } else {
        this.areaOptions = [];
      }
    },
    populateRowOptions() {
      if (this.area.length === 1) {
        const selectedAreaValue = this.area[0].value;
        const selectedStand = this.Data.find(stand =>
          stand.areas.some(area => area.value === selectedAreaValue)
        );

        if (selectedStand) {
          const selectedArea = selectedStand.areas.find(area => area.value === selectedAreaValue);
       if (selectedArea && selectedArea.rows) {
            this.rowOptions = selectedArea.rows.map(row => ({
              value: row.value,
              text: row.text,
            })).sort((a, b) => a.value - b.value);
           
          } else {
            this.rowOptions = [];
          }
        }
      } else {
        this.rowOptions = [];
      }
    },
   
    populateSeatOptions() {
  if (this.row.length === 1) {
    const selectedRowValue = this.row[0].value; 
   const selectedStand = this.Data.find(stand =>
      stand.areas.some(area =>
        area.rows.some(row => row.value === selectedRowValue)
      )
    );
    if (selectedStand) {
      const selectedArea = selectedStand.areas.find(area =>
        area.rows.some(row => row.value === selectedRowValue)
      );

      if (selectedArea) {
        const selectedRow = selectedArea.rows.find(row => row.value === selectedRowValue);
     if (selectedRow && selectedRow.seats) {
          const uniqueSeats = Array.from(new Set(selectedRow.seats));

          this.seatOptions = uniqueSeats.map(seat => ({
            value: seat,
            text: seat,
          })).sort((a, b) => a.value - b.value);
        } else {
          this.seatOptions = [];
        }
      }
    }
  } else {
    this.seatOptions = [];
  }
},
    onStandChange() {
      this.area = [];
      this.row = [];
      this.seat = [];
      this.populateAreaOptions();
      this.rowOptions = [];
      this.seatOptions = [];
    },
    onAreaChange() {
      this.row = [];
      this.seat = [];
      this.populateRowOptions();
      this.seatOptions = [];
    },
    onRowChange() {
      this.seat = [];
      this.populateSeatOptions();
    },

async addSiipSetup() {
  this.submitted = true;
  this.$v.$touch();  

  if (this.$v.$invalid) {
    this.failedmsg("Missing stand.");
    return;
  }
const requestData = {
  stand: this.stand.map(s => s.value).join(','),
  area: this.area.map(a => a.value).join(','),
  row: this.row.map(r => r.value).join(','),
  seat: this.seat.map(s => s.value).join(','),
};

try {
  this.tryingToSubmit = true;
  const response = await Ticketing.addSiipSetup(requestData);
  if (response.data && response.data.data && !response.data.error) {
   
       const successMessage = Object.keys(response.data.data)[0];
       this.successmsg(successMessage);
       this.showModal = false;
       this.refreshData();
      }
  else if (response.data.error) {
    this.failedmsg(response.data.error);
    this.showModal = false;
  }
  this.tryingToSubmit = false;
}catch (error) {
  this.failedmsg(
    error.response?.data?.error || "An error occurred"
  );
  this.showModal = false;  
  this.tryingToSubmit = false;
}
},
    refreshData() {
      this.$emit('onRefresh');
    },
    failedmsg(msg) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: msg,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    successmsg(msg) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: msg,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    resetFormData() {
      this.stand = [];
      this.area = [];
      this.row = [];
      this.seat = [];
      this.areaOptions = [];
      this.rowOptions = [];
      this.seatOptions = [];
    },
   
    closeModal() {
  this.showModal = false;
  this.$emit('close');
}
  },
};
</script>
  <template>
    <b-modal :key="modalKey" v-model="showModal" id="add_siip_setup" title="Add SIIP Setup" title-class="font-18" centered @show="resetFormData">
      <template v-if="showLoader">
        <div class="d-flex justify-content-center align-items-center" style="height: 200px;">
          <b-spinner small></b-spinner>
          <span class="ml-2">Loading...</span>
        </div>
      </template>
      <template v-else>
        <form @submit.prevent="addSiipSetup">
          <div class="row">
            <b-card-text>
              <div class="row">
                <b-form-group label="Stand" label-for="formrow-stand-input" class="mb-3">
                  <multiselect v-model="stand" :options="standOptions" :multiple="true" label="text" track-by="value" @change="onStandChange"></multiselect>
                  <validationMessages v-if="submitted" :fieldName="'Stand'" :validationName="$v.stand"></validationMessages>
                </b-form-group>
                
                <b-form-group label="Area" label-for="formrow-area-input" class="mb-3">
                  <multiselect v-model="area" :options="areaOptions" :multiple="true" :disabled="stand.length !== 1" label="text" track-by="value" @change="onAreaChange"></multiselect>
                </b-form-group>
                <b-form-group label="Row" label-for="formrow-row-input" class="mb-3">
                  <multiselect v-model="row" :options="rowOptions" :multiple="true" :disabled="area.length !== 1" label="text" track-by="value" @change="onRowChange"></multiselect>
                </b-form-group>
                <b-form-group label="Seat" label-for="formrow-seat-input" class="mb-3">
                  <multiselect v-model="seat" :options="seatOptions" :multiple="true" :disabled="row.length !== 1" label="text" track-by="value"></multiselect>
                </b-form-group>
                <input type="hidden" name="csrf_token" v-model="csrf_token">
              </div>
            </b-card-text>
          </div>
        </form>
      </template>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="closeModal">Close</b-button>
        <b-button variant="primary" @click="addSiipSetup" :disabled="tryingToSubmit || showLoader">
          <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
        </b-button>
      </template>
    </b-modal>
  </template>
  
  
  
